<template>
    <img
        loading="lazy"
        :srcset="mediaItem.srcset"
        onload="window.requestAnimationFrame(function(){if(!(size=getBoundingClientRect().width))return;onload=null;sizes=Math.ceil(size/window.innerWidth*100)+'vw';});"
        sizes="1px"
        draggable="false"
        :src="mediaItem.url"
        :alt="mediaItem.name"
    >
</template>

<script>
export default {
    name: "ResponsiveImage",
    props: {
        mediaItem: {
            type: Object,
            required: true
        }
    }
}
</script>
